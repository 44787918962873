import { Route } from '@angular/router';

export const financeRoutes: Route = {
    loadComponent: () => import('./finance.component'),
    children: [
        {
            path: '',
            redirectTo: 'ready-for-payout',
            pathMatch: 'full',
        },
        {
            path: 'ready-for-payout',
            loadComponent: () => import('./ready-for-payout/ready-for-payout.component'),
            title: 'readyForPayout.title',
        },
    ],
};
