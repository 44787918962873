import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HasPermissionDirective } from '@nuis/common';
import { SidebarGroupComponent } from './components/sidebar-group.component';
import { SidebarItemComponent } from './components/sidebar-item.component';
import { SidebarElement } from './models/sidebar-element.model';

@Component({
    selector: 'luis-sidebar',
    standalone: true,
    imports: [RouterLink, HasPermissionDirective, SidebarItemComponent, SidebarGroupComponent],
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
    public items = input.required<SidebarElement[]>();
}
