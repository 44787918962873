import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent, SidebarComponent, SidebarElement } from '@luis/luis/navigation';

@Component({
    selector: 'fin-root',
    standalone: true,
    imports: [RouterOutlet, SidebarComponent, NavbarComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    protected sidebarItems = signal<SidebarElement[]>([
        {
            type: 'item',
            displayName: 'readyForPayout.title',
            route: ['/ready-for-payout'],
            icon: 'pi pi-wallet',
        },
    ]);
}
