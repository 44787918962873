import { NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
    AuthService,
    Configuration,
    LanguageService,
    mapProductCategoryToRoute,
    ProfilePictureComponent,
    Theme,
    ThemeService,
    UserService,
} from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { firstValueFrom } from 'rxjs';
import { SIDEBAR_LANGUAGES } from './models/sidebar-language.model';

@Component({
    selector: 'luis-navbar',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        TranslatePipe,
        ButtonModule,
        DropdownModule,
        DividerModule,
        OverlayPanelModule,
        ProfilePictureComponent,
    ],
    templateUrl: './navbar.component.html',
})
export class NavbarComponent {
    private readonly authService = inject(AuthService);
    private readonly userService = inject(UserService);
    private readonly languageService = inject(LanguageService);
    private readonly themeService = inject(ThemeService);
    private readonly config = inject(Configuration);

    protected user = this.userService.user;

    protected languages = SIDEBAR_LANGUAGES;
    protected selectedLanguage = this.languageService.language;

    protected selectedTheme = this.themeService.theme;

    protected luisProducts = signal<{ icon: string; title: string; description: string | null; link: string }[]>([
        {
            icon: 'pi pi-compass',
            title: 'product.travel',
            description: 'A1',
            link: `${this.config.appUrls.luis}/${mapProductCategoryToRoute('TravelInsurance')}`,
        },
        {
            icon: 'pi pi-wallet',
            title: 'product.luisFinance',
            description: 'Accounting',
            link: this.config.appUrls.finance,
        },
    ]);

    protected crmProducts = signal<{ icon: string; title: string; description: string | null; link: string }[]>([
        {
            icon: 'pi pi-phone',
            title: 'product.handset',
            description: 'AUT BGR SRB MKD BGR HRK',
            link: 'https://crm.integral.local/Ant20/main.aspx?etc=10023&extraqs=%3fpagemode%3diframe%26sitemappath%3dANT%257cAdministration%257cant_policy&pagetype=entitylist&viewid=%7b07CC3B16-D471-4592-87B8-EA2DD6D5ADC5%7d&viewtype=1039',
        },
        {
            icon: 'pi pi-compass',
            title: 'product.travel',
            description: 'Drei-AUT',
            link: 'https://crm.integral.local/Lamie/main.aspx?area=lamie_travelinsurancecontract&etc=10146&page=Reiseversicherung&pageType=EntityList&web=true',
        },
        {
            icon: 'pi pi-compass',
            title: 'product.travel',
            description: 'A1-BGR',
            link: 'https://lamieag.crm4.dynamics.com/main.aspx?appid=d64c3ab9-2514-4c3f-9163-094be014a5f3&pagetype=entitylist&etn=lamie_ti2gocontract&viewid=5262675a-c7a1-4ef5-ac16-44a5c2a502bf&viewType=1039',
        },
        {
            icon: 'pi pi-shield',
            title: 'product.cyber',
            description: 'Drei & A1',
            link: 'https://crm.integral.local/Ant20/main.aspx?etc=10038&extraqs=%3fpagemode%3diframe%26sitemappath%3dANT%257cCyberInsurance%257cant_cyberprotectioncontract&pagetype=entitylist&viewid=%7bC1E3DF3C-67F4-430F-B4DF-ECBB84844180%7d&viewtype=1039',
        },
        {
            icon: 'pi pi-cloud',
            title: 'product.weddie',
            description: null,
            link: 'https://crm.integral.local/Lamie/main.aspx?etc=10106&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_weddinginsurancecontract&pagetype=entitylist&viewid=%7b92E1265B-AB0F-E711-80CE-00155DD46FB8%7d&viewtype=1039',
        },
        {
            icon: 'pi pi-ban',
            title: 'product.weddiestorno',
            description: null,
            link: 'https://crm.integral.local/Lamie/main.aspx?etc=10106&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_weddinginsurancecontract&pagetype=entitylist&viewid=%7b053F7421-AB0F-E711-80CE-00155DD46FB8%7d&viewtype=1039',
        },
        {
            icon: 'pi pi-home',
            title: 'product.home',
            description: null,
            link: 'https://crm.integral.local/Lamie/main.aspx?etc=10014&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_contract&pagetype=entitylist&viewid=%7b5D015258-22E7-4079-8C3C-DD1D576E36D1%7d&viewtype=1039',
        },
    ]);

    protected setTheme(theme: Theme) {
        this.themeService.useTheme(theme);
    }

    protected setLanguage(lang: string) {
        if (this.selectedLanguage() !== lang) {
            this.languageService.useLanguage(lang);
            location.reload();
        }
    }

    protected async logout() {
        await firstValueFrom(this.authService.logout());
    }
}
