import { Route } from '@angular/router';

export const claimDetailsRoute: Route = {
    loadComponent: () => import('./claim-details.component'),
    title: 'claimDetails',
    children: [
        { path: '', redirectTo: 'overview', pathMatch: 'full' },
        {
            path: 'overview',
            loadComponent: () => import('./overview-tab/overview-tab.component'),
        },
        {
            path: 'payments',
            loadComponent: () => import('./payments-tab/payments-tab.component'),
        },
        {
            path: 'payments/invoice/:invoiceId',
            loadComponent: () => import('./payments-tab/invoice-details/invoice-details.component'),
        },
        {
            path: 'documents',
            loadComponent: () => import('./documents-tab/documents-tab.component'),
        },
        {
            path: 'audits',
            loadComponent: () => import('./audits-tab/audits-tab.component'),
        },
    ],
};
