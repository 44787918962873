import { Route } from '@angular/router';

export const contractDetailsRoute: Route = {
    loadComponent: () => import('./contract-details.component'),
    title: 'contractDetails',
    children: [
        { path: '', redirectTo: 'overview', pathMatch: 'full' },
        {
            path: 'overview',
            loadComponent: () => import('./overview-tab/overview-tab.component'),
        },
        {
            path: 'claims',
            loadComponent: () => import('./claims-tab/claims-tab.component'),
        },
        {
            path: 'documents',
            loadComponent: () => import('./documents-tab/documents-tab.component'),
        },
        {
            path: 'notifications',
            loadComponent: () => import('./notifications-tab/notifications-tab.component'),
        },
        {
            path: 'transactions',
            loadComponent: () => import('./transactions-tab/transactions-tab.component'),
        },
        {
            path: 'additional',
            loadComponent: () => import('./additional-tab/additional-tab.component'),
        },
        {
            path: 'audits',
            loadComponent: () => import('./audits-tab/audits-tab.component'),
        },
    ],
};
