<div class="flex h-screen w-screen">
    <div class="h-screen w-18rem">
        <luis-sidebar [items]="sidebarItems()" />
    </div>

    <div class="flex flex-column h-screen flex-1 overflow-hidden">
        <luis-navbar class="z-1" />

        <div class="flex-1 overflow-hidden">
            <router-outlet />
        </div>
    </div>
</div>
