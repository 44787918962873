import { Route } from '@angular/router';

export const frameworkContractDetailsRoute: Route = {
    loadComponent: () => import('./framework-contract-details.component'),
    title: 'frameworkContractDetails',
    children: [
        { path: '', redirectTo: 'overview', pathMatch: 'full' },
        {
            path: 'overview',
            loadComponent: () => import('./overview-tab/overview-tab.component'),
        },
        {
            path: 'linked-contracts',
            loadComponent: () => import('./linked-contracts-tab/linked-contracts-tab.component'),
        },
        {
            path: 'documents',
            loadComponent: () => import('./documents-tab/documents-tab.component'),
        },
        {
            path: 'notifications',
            loadComponent: () => import('./notifications-tab/notifications-tab.component'),
        },
        {
            path: 'additional',
            loadComponent: () => import('./additional-tab/additional-tab.component'),
        },
        {
            path: 'audits',
            loadComponent: () => import('./audits-tab/audits-tab.component'),
        },
    ],
};
